export const CARRIERS = {
  PROGRESSIVE: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/progressive.png',
    alt: 'Progressive'
  },
  NATIONWIDE: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/nationwide.png',
    alt: 'Nationwide'
  },
  SAFECO: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/safeco.png',
    alt: 'Safeco Insurance'
  },
  TRAVELERS: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/travelers.png',
    alt: 'Travelers'
  },
  MERCURY: {
    url: 'https://maticinsurance.sirv.com/carriers/black/mercury-logo-grey.png',
    alt: 'Mercury Insurance'
  },
  LEMONADE: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/Lemonade.png',
    alt: 'Lemonade'
  },
  CSAA: {
    url: 'https://maticinsurance.sirv.com/carriers/black/csaa.PNG',
    alt: 'CSAA'
  },
  UNIVERSAL: {
    url: 'https://maticinsurance.sirv.com/carriers/black/universal.png',
    alt: 'Universal'
  },
  BRANCH: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/branch.png',
    alt: 'Branch'
  },
  ALLSTATE: {
    url: 'https://maticinsurance.sirv.com/carriers/black/Allstate-logo-grey.png',
    alt: 'Allstate'
  },
  FOREMOST: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/foremost.png',
    alt: 'Foremost'
  },
  STILLWATER: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/stillwater.png',
    alt: 'Stillwater'
  },
  TOWERHILL: {
    url: 'https://maticinsurance-cdn.sirv.com/carriers/black/towerhill.png',
    alt: 'Towerhill'
  }
};
