import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const wrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 999;
  padding: 0 16px;

  ${mq[0]} {
    width: 80%;
    margin: 40px auto 0;
  }

  ${mq[1]} {
    padding: 0;
  }
`;

export const headingCSS = css`
  font-size: 32px;
  font-weight: bold;
  z-index: 99;

  ${mq[1]} {
    width: 825px;
  }
`;

export const descriptionCSS = css`
  font-size: 14px;
  margin: 4px 0 21px;

  ${mq[0]} {
    font-size: 16px;
    margin: 4px 0 36px;
  }
`;

export const iconCSS = css`
  margin: 40px 0 0;
  width: 108px;

  ${mq[0]} {
    margin: 58px 0 0;
  }
`;

export const carrierGridWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 88%;

  ${mq[0]} {
    max-width: 100%;
  }

  @media screen and (min-width: 1400px) {
    width: 992px;
  }
`;

export const carouselWrapperCSS = css`
  width: 100%;
  margin-top: 20px;
  overflow-x: hidden;
  height: 258px;
`;
