/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';
import { headingCSS, faqCSS, accordionWrapperCSS, contentWrapperCSS, contentImageCSS } from './FAQExpiration.style';
import FAQConstants from './FAQ.constants';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import Accordion from 'src/shared/components/Accordion/Accordion';
import noop from 'src/utils/noop';
import { FAQProps } from './FAQ.types';

import { DefaultLocation } from 'src/interfaces/IPage';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

const FAQExpiration: React.FC<FAQProps> = ({
  page,
  customCSS,
  onFaqClick = noop,
  heading = 'Quick Questions we can Answer',
  accordionItems = []
}) => {
  const { flow, gid } = useParams() as DefaultLocation;

  const onAccordionClick = ({ title }: AccordionItem, ordinal: number) => {
    analytics.track(SEGMENT.FAQ_CLICKED, gid, flow, {
      ordinal,
      question_text: title,
      page
    });

    onFaqClick({ title, ordinal });
  };

  return (
    <section css={[faqCSS, customCSS]}>
      <h2 css={headingCSS}>{heading}</h2>
      <div css={contentWrapperCSS}>
        <img
          css={contentImageCSS}
          src="https://maticinsurance.sirv.com/customer-experience/office-with-plane.png"
          alt="Agents Working"
          loading="lazy"
        />

        <div css={accordionWrapperCSS}>
          <Accordion
            items={accordionItems?.length > 0 ? accordionItems : FAQConstants.items}
            onChange={onAccordionClick}
          />
        </div>
      </div>
    </section>
  );
};

export default FAQExpiration;
