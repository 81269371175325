import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text32, text24 } from 'src/theme/typography';

export const headingCSS = css`
  ${text24};
  margin: 0 0 14px;

  ${mq[0]} {
    ${text32};
  }
`;

export const faqCSS = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const accordionWrapperCSS = css`
  width: 100%;
  ${mq[0]} {
    max-width: 380px;
  }
`;

export const contentWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const contentImageCSS = css`
  max-width: 90%;

  ${mq[0]} {
    max-width: 50%;
    height: 250px;
  }
  ${mq[1]} {
    height: 400px;
    max-width: 60%;
  }
`;
