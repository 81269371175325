import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text24, text40 } from 'src/theme/typography';

export const headingCSS = css`
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 12px;

  ${mq[0]} {
    font-size: 40px;
    padding-bottom: 8px;
  }
`;

export const descriptionCSS = css`
  font-size: 16px;

  ${mq[0]} {
    font-size: 20px;
    max-width: 500px;
    margin: 8px auto 25px;
  }

  ${mq[1]} {
    max-width: 650px;
  }
`;

export const scrollCSS = css`
  margin: auto;
  position: absolute;
  bottom: 5%;
  cursor: pointer;
  z-index: 99;

  ${mq[0]} {
    bottom: 15%;
  }
`;

export const heroCSS = css`
  padding: 40px 16px 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;

  ${mq[0]} {
    padding-top: 80px 0 0;
  }
`;

export const houseImgCSS = css`
  @media screen and (max-width: 400px) {
    height: 100px;
  }

  height: 140px;

  ${mq[0]} {
    width: 500px;
    height: unset;
  }
`;

export const heroContentCSS = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const componentsPaddingCSS = css`
  padding: 32px 12px;

  ${mq[0]} {
    padding: 56px 20px;
  }

  ${mq[1]} {
    padding: 56px 12px;
  }
`;

export const componentsBackgroundCSS = css`
  background-color: ${colors.grayFive};
`;

export const userProfileCSS = css`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const faqCSS = css`
  overflow-x: hidden;
`;

export const userProfileWrapperCSS = css`
  min-width: 300px;
`;

export const profileHeadingCss = css`
  display: none;
`;

export const heroHeadingCustomCSS = css`
  ${text24};

  ${mq[0]} {
    ${text40};
    padding: 0;
  }
`;

export const heroFormWrapperCustomCSS = css`
  padding-top: 0;

  ${mq[0]} {
    padding-top: 48px;
  }
`;

export const housesWrapperCSS = css`
  @media only screen and (max-width: 400px) {
    padding-bottom: 140px;
  }

  padding: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
`;
