/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import {
  componentsBackgroundCSS,
  componentsPaddingCSS,
  faqCSS,
  heroCSS,
  profileHeadingCss,
  userProfileCSS,
  userProfileWrapperCSS
} from './AllFunnelLanding.style';

import { DefaultLocation } from 'src/interfaces/IPage';
import useConfig from 'src/api/config/useConfig';
import useResponsive from 'src/hooks/useResponsive';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import Container from 'src/kit/Container/Container';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import CarriersList from 'src/components/CarriersList/CarriersList';
import HowItWorksSteps from 'src/components/HowItWorksSteps/HowItWorksSteps';
import FAQ from 'src/components/FAQ/FAQ';
import { getAge } from 'src/utils/date';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { Answers } from 'src/interfaces/IAnswer';
import WhyTrustMatic from 'src/components/WhyTrustMatic/WhyTrustMatic';
import AllFunnelOfferForm from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm';
import {
  getAccordionItems,
  getCarriersListHeader,
  getCarriersListSubheader
} from 'src/components/Savings/Savings.utils';
import { getAllFunnelLookupSteps } from 'src/components/HowItWorksSteps/HowItWorksSteps.utils';
import SERVICERS, { getServicerNameToDisplay } from 'src/constants/servicers';
import QuotesUserProfile from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/QuotesUserProfile';
import { Disclosure } from 'src/interfaces/disclosures.types';
import { isPhoneHidden } from 'src/utils/phone';
import { useMutateSession } from 'src/api/session';
import { allFunnelService } from 'src/api/allFunnel';
import { useDisclosures } from 'src/api/disclosures';
import { AFAnswers } from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm.types';
import useQuestions from 'src/api/questions/useQuestions';
import { useSession } from 'src/api/session';
import { googleReviewsCSS } from 'src/components/SavingsMainContent/SavingsMainContent.style';

const AllFunnelLanding: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();

  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);
  const { data: questions } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const {
    stories,
    person_phone,
    person_first_name,
    person_last_name,
    property_address,
    current_policy,
    property_year_built,
    property_square_feet
  } = answers as AFAnswers;
  const { data: sessionData, isLoading: isSessionDataLoading } = useSession(flow, gid);
  const { mutateAsync: mutateSession, isLoading } = useMutateSession(flow, gid);

  const [isUserPhoneUpdated, setIsUserPhoneUpdated] = useState(false);
  const isPhhNewRezServicer = config?.servicer_key === SERVICERS.PHH_NRZ;
  const isNewRezServicer = config?.servicer_key === SERVICERS.NEW_REZ_NRZ;
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);
  const flowStartedReported = useRef(false);

  useEffect(() => {
    if (!flowStartedReported.current && config?.servicer_key) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });
      flowStartedReported.current = true;
    }
  }, [flow, gid, config]);

  useEffect(() => {
    if (answers.person_gid && config?.servicer_key) {
      const address = answers.property_address as AddressAnswer;

      analytics.identify(answers.person_gid, {
        email: analytics.generateTrackEmail(answers.person_gid as string),
        state: address?.state ?? undefined,
        age: answers.person_date_of_birth ? getAge(answers.person_date_of_birth as string) : undefined
      });

      analytics.page(SEGMENT.PAGES.All_FUNNEL, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });
    }
  }, [answers, flow, gid, config]);
  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, gid, flow, {
      layout: isMobile ? 'mobile' : 'desktop',
      location: SEGMENT.PAGES_KEY.ALL_FUNNEL
    });

  const handleSubmit = async (_phone: string | null) => {
    analytics.track(SEGMENT.PRIMARY_CTA_CLICKED, gid, flow);
    await allFunnelService.createLead(gid);

    disclosuresData?.disclosures?.forEach((disclosure: Disclosure) => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.ALL_FUNNEL
      });
    });

    analytics.track(SEGMENT.QUOTES_REQUESTED, gid, flow);

    const answersToUpdate = questions?.answers || ({} as Answers);
    const userPhone = answersToUpdate.person_phone as string;

    if (userPhone && isPhoneHidden(userPhone)) {
      delete answersToUpdate.person_phone;
    }

    await mutateSession({ quotes_request: true, answers: answersToUpdate });

    analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
    return window.open(sessionData?.digital_profile_url, '_self');
  };

  if ((!isUserPhoneUpdated && isConfigLoading) || !config?.gid || isSessionDataLoading) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <div css={heroCSS}>
        <AllFunnelOfferForm
          onSubmit={handleSubmit}
          onUserPhoneUpdated={setIsUserPhoneUpdated}
          phoneValue={person_phone ?? null}
          isPageSubmitting={isLoading}
        />
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <Fragment>
          <h2 css={profileHeadingCss}>It’s easy as 1, 2, 3</h2>
          <div css={userProfileCSS}>
            <QuotesUserProfile
              customCSS={userProfileWrapperCSS}
              firstName={person_first_name}
              lastName={person_last_name}
              carrierName={current_policy?.carrier_name}
              currentPolicy={current_policy}
              homeDetails={{
                yearBuilt: property_year_built,
                squareFeet: property_square_feet,
                stories: stories
              }}
              address={property_address}
            />

            <HowItWorksSteps
              heading={isMobile ? 'It’s easy as 1, 2, 3' : ''}
              steps={getAllFunnelLookupSteps(isNewRezServicer)}
            />
          </div>
        </Fragment>
      </Container>
      <Container customCSS={componentsPaddingCSS}></Container>
      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <CarriersList
            header={getCarriersListHeader(config.servicer_key, servicerName)}
            subheader={getCarriersListSubheader(isPhhNewRezServicer, servicerName)}
          />
        </Container>
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <GoogleReviews
          customCSS={{ wrapper: googleReviewsCSS }}
          heading="Why homeowners trust Matic"
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
      </Container>
      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <WhyTrustMatic savings={config?.partner.savings_label ?? null} isNewRez={isNewRezServicer} />
        </Container>
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <FAQ customCSS={faqCSS} page={SEGMENT.PAGES.All_FUNNEL} accordionItems={getAccordionItems(servicerName)} />
      </Container>
    </div>
  );
};

export default AllFunnelLanding;
