/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  componentsBackgroundCSS,
  componentsPaddingCSS,
  faqCSS,
  headingCSS,
  heroCSS,
  descriptionCSS,
  heroContentCSS,
  scrollCSS,
  houseImgCSS,
  housesWrapperCSS
} from './AllFunnelExpirationLanding.style';

import { DefaultLocation } from 'src/interfaces/IPage';
import useConfig from 'src/api/config/useConfig';
import useResponsive from 'src/hooks/useResponsive';
import Container from 'src/kit/Container/Container';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { getAge } from 'src/utils/date';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { Answers } from 'src/interfaces/IAnswer';
import WhyTrustMatic from 'src/components/WhyTrustMatic/WhyTrustMatic';
import useQuestions from 'src/api/questions/useQuestions';
import { googleReviewsCSS } from 'src/components/SavingsMainContent/SavingsMainContent.style';
import FAQExpiration from 'src/components/FAQ/FAQExpiration';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import { getAccordionItems } from 'src/components/Savings/Savings.utils';
import HowItWorksExpiration from 'src/components/HowItWorksExpiration/HowItWorksExpiration';
import PartnershipCarousel from 'src/components/PartnershipCarousel/PartnershipCarousel';
import LeftHouseImg from 'src/assets/svg/left-house.svg?react';
import RightHouseImg from 'src/assets/svg/right-house.svg?react';
import { useMutateSession, useSession } from 'src/api/session';
import { allFunnelService } from 'src/api/allFunnel';
import { useDisclosures } from 'src/api/disclosures';
import { Disclosure } from 'src/interfaces/disclosures.types';
import { isPhoneHidden } from 'src/utils/phone';
import AllFunnelOfferForm from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { scrollToNode } from 'src/utils/scroll';
import GoogleReviewRating from 'src/components/Landing/GoogleReviewRating/GoogleReviewRating';

const AllFunnelExpirationLanding: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const { data: disclosuresData } = useDisclosures(gid);
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: questions } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const flowStartedReported = useRef(false);
  const [isUserPhoneUpdated, setIsUserPhoneUpdated] = useState(false);
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);
  const { mutateAsync: mutateSession, isLoading } = useMutateSession(flow, gid);
  const { data: sessionData, isLoading: isSessionDataLoading } = useSession(flow, gid);

  useEffect(() => {
    if (!flowStartedReported.current && config?.servicer_key) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });
      flowStartedReported.current = true;
    }
  }, [flow, gid, config]);

  useEffect(() => {
    if (answers.person_gid && config?.servicer_key) {
      const address = answers.property_address as AddressAnswer;

      analytics.identify(answers.person_gid, {
        email: analytics.generateTrackEmail(answers.person_gid as string),
        state: address?.state ?? undefined,
        age: answers.person_date_of_birth ? getAge(answers.person_date_of_birth as string) : undefined
      });

      analytics.page(SEGMENT.PAGES.All_FUNNEL, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });
    }
  }, [answers, flow, gid, config]);
  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, gid, flow, {
      layout: isMobile ? 'mobile' : 'desktop',
      location: SEGMENT.PAGES_KEY.ALL_FUNNEL
    });

  const handleSubmit = async (_phone: string | null) => {
    analytics.track(SEGMENT.PRIMARY_CTA_CLICKED, gid, flow);
    await allFunnelService.createLead(gid);

    disclosuresData?.disclosures?.forEach((disclosure: Disclosure) => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.ALL_FUNNEL
      });
    });

    analytics.track(SEGMENT.QUOTES_REQUESTED, gid, flow);

    const answersToUpdate = questions?.answers || ({} as Answers);
    const userPhone = answersToUpdate.person_phone as string;

    if (userPhone && isPhoneHidden(userPhone)) {
      delete answersToUpdate.person_phone;
    }

    await mutateSession({ quotes_request: true, answers: answersToUpdate });

    analytics.track(SEGMENT.DIGITAL_PROFILE_URL_REDIRECTED, gid, flow);
    return window.open(sessionData?.digital_profile_url, '_self');
  };

  if ((!isUserPhoneUpdated && isConfigLoading) || !config?.gid || isSessionDataLoading) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <div css={heroCSS}>
        <div css={heroContentCSS}>
          <div css={headingCSS}>Your policy renews soon — let’s look for a lower rate</div>
          <div css={descriptionCSS}>
            Now’s the time to check for a better home insurance quote. Take a minute to see if you could save before
            your renewal.
          </div>
        </div>

        <AllFunnelOfferForm
          isExpirationVariation
          onSubmit={handleSubmit}
          onUserPhoneUpdated={setIsUserPhoneUpdated}
          phoneValue={(answers?.person_phone as string) ?? null}
          isPageSubmitting={isLoading}
        />

        <GoogleReviewRating isSimplified />

        <div css={scrollCSS} onClick={() => scrollToNode('how-matic-works-section')} role="presentation">
          <div>
            {' '}
            <b> Scroll</b> to see how{' '}
          </div>
          <div>Matic works</div>

          <img src="https://maticinsurance.sirv.com/customer-experience/heart.gif" width="50" height="50" alt="" />
        </div>
        <div css={housesWrapperCSS}>
          <LeftHouseImg css={houseImgCSS} />
          <RightHouseImg css={houseImgCSS} />
        </div>
      </div>

      <PartnershipCarousel servicerName={servicerName} />

      <HowItWorksExpiration savingsLabel={config?.partner.savings_label} />
      <Container customCSS={componentsPaddingCSS}>
        <GoogleReviews
          isExpirationVariation
          customCSS={{ wrapper: googleReviewsCSS }}
          heading="Why homeowners trust Matic"
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
      </Container>
      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <WhyTrustMatic isExpirationVariation savings={config?.partner.savings_label ?? null} />
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <FAQExpiration
          customCSS={faqCSS}
          page={SEGMENT.PAGES.All_FUNNEL}
          accordionItems={getAccordionItems(servicerName)}
        />
      </Container>
    </div>
  );
};

export default AllFunnelExpirationLanding;
