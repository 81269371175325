import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import StemImg1 from 'src/assets/svg/step-1.svg';

export const stepsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 60px 40px 0;

  ${mq[0]} {
    padding-top: 80px 0 0;
  }

  ${mq[1]} {
    width: 80%;
  }
`;

export const stepWrapperCSS = (isReverted?: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  ${isReverted &&
  css`
    flex-direction: column-reverse;
  `}

  ${mq[0]} {
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px auto;
  }
`;

export const stepImageCSS = css`
  position: relative;
  max-width: 338px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${mq[0]} {
    max-width: unset;
    flex-basis: 40%;
  }

  ${mq[1]} {
    flex-basis: 50%;
  }
`;

export const stepDescriptionCSS = (isLeftOriented: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;

  ${mq[0]} {
    margin-bottom: 0;

    ${isLeftOriented
      ? css`
          margin-left: 40px;
        `
      : css`
          margin-right: 40px;
        `}
  }

  ${mq[1]} {
    ${isLeftOriented
      ? css`
          margin-left: 130px;
        `
      : css`
          margin-right: 130px;
        `}
  }
`;

export const stepHeadingWrapperCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${mq[0]} {
    margin-bottom: 32px;
  }
`;

export const stepCopyCSS = css`
  width: 100%;
  font-size: 16px;

  ${mq[0]} {
    width: 320px;
  }

  ${mq[1]} {
    width: 380px;
    font-size: 20px;
  }
`;

export const stepHeadingNumberCSS = css`
  color: ${colors.cheriseLight};
  font-weight: bold;
  font-size: 105px;
  line-height: 100px;
  margin-right: 24px;

  ${mq[0]} {
    font-size: 130px;
  }
`;

export const stepHeadingCSS = css`
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;

  ${mq[1]} {
    width: 300px;
    font-size: 44px;
    line-height: 54px;
  }
`;

export const savingsWrapperCSS = css`
  position: absolute;
  right: 25%;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  ${mq[1]} {
    font-size: 18px;
  }
`;

export const savingsCSS = css`
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;

  ${mq[1]} {
    font-size: 36px;
    line-height: 34px;
  }
`;

export const headingCSS = css`
  font-size: 32px;
  font-weight: bold;

  ${mq[0]} {
    font-size: 40px;
  }
`;

export const descriptionCSS = css`
  font-size: 14px;
  text-align: center;
  margin-bottom: 40px;

  ${mq[0]} {
    font-size: 16px;
    margin-bottom: 60px;
  }
`;

export const profileWrapperCSS = css`
  background-image: url(${StemImg1});
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  height: 300px;
  width: 300px;

  ${mq[0]} {
    height: 290px;
  }

  ${mq[1]} {
    height: 370px;
    width: 400px;
  }
`;
export const profileCustomCSS = css`
  top: 7%;
  position: absolute;
  width: 210px;
  left: 24px;
  padding-top: 10;
  padding-bottom: 10;

  ${mq[1]} {
    top: 18%;
    position: absolute;
    width: 410px;
    left: -25px;
  }
`;
