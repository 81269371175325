/** @jsxImportSource @emotion/react */

import {
  descriptionCSS,
  headingCSS,
  wrapperCSS,
  iconCSS,
  carrierGridWrapperCSS,
  carouselWrapperCSS
} from './PartnershipCarousel.style';
import { expirationPolicyCarriersLogoList } from 'src/components/Landing/Landing.utils';
import useResponsive from 'src/hooks/useResponsive';
import CarriersCarousel from 'src/components/CarriersCarousel/CarriersCarousel';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import { carrierCSS } from 'src/components/Landing/UnsupportedCarrierLanding/UnsupportedCarrierLanding.style';
import { CARRIERS } from './PartnershipCarousel.util';
import DividerIcon from 'src/assets/svg/curvy-divider.svg?react';

interface PartnershipCarouselProps {
  servicerName?: string | React.ReactElement;
}

const PartnershipCarousel: React.FC<PartnershipCarouselProps> = ({ servicerName }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={wrapperCSS}>
      <div css={headingCSS}>{servicerName} and Matic teamed up to help you search for home insurance savings</div>
      <div css={descriptionCSS}>Shop 40+ A-rated carriers instantly to look for a better rate. </div>
      <div css={carrierGridWrapperCSS}>
        {isMobile ? (
          <div css={carouselWrapperCSS}>
            <CarriersCarousel carriersList={CARRIERS} />
          </div>
        ) : (
          <div css={carrierGridWrapperCSS}>
            {expirationPolicyCarriersLogoList.map((carrier, i) => (
              <div key={i}>
                <SirvImage css={carrierCSS} url={carrier.url} width={113} alt={carrier.alt} />
              </div>
            ))}
          </div>
        )}
      </div>
      <DividerIcon css={iconCSS} />
    </div>
  );
};

export default PartnershipCarousel;
